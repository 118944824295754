import App, { DispatchFromProps, StateFromProps } from './App';
import {
  attemptGetGlobalSettings,
  attemptGetMessages,
  attemptGetShiftDefs,
  atteptGetUnreadMessagesCount,
  changeDate,
  closeModal,
  getHolidays,
  hideErrorModal,
  queueToast,
  setLocationForTracking,
  setShowingRuleViolationInfo,
  toggleMenu,
  toggleScreenBig,
  toggleScreenSmall,
} from './appActions';
import {
  attemptLoadTeamRoster,
  changePeriod,
  changeTeam,
} from './ListView/ListViewActions';

import { Period } from './util/dates';
import { Store, connect } from './rootReducer';
import { ThunkDispatch } from './types';
import { logout } from './Login/LoginAction';

const mapStateToProps = (store: Store): StateFromProps => ({
  currentDate: store.appReducer.globalSettings.useDateTime,
  idleLogoutSeconds: store.appReducer.globalSettings.idleLogoutSeconds,
  employeeMap: store.listViewReducer.employeeMap,
  shouldShowMenu: store.appReducer.shouldShowMenu,
  modalLoading: store.appReducer.modalLoading,
  version: store.appReducer.version,
  openModals: store.appReducer.openModals,
  toast: store.appReducer.toast,
  errorModal: store.appReducer.errorModal,
  showingRuleViolationInfo: store.appReducer.showingRuleViolationInfo,
  showingDialog:
    store.appReducer.confirmDialog.shown ||
    store.appReducer.errorModal.shown ||
    store.appReducer.infoDialog.shown,
  teams: store.chooseTeamReducer.teams,
  failedPollingEndpoints: store.appReducer.failedPollingEndpoints,
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchFromProps => ({
  changeDate: (date: Date) => {
    const newDate = new Date(date);
    newDate.setHours(2); // Adding some hours to avoid being one day off on account of UTC
    dispatch(changeDate(newDate));
  },
  toggleMenu: () => {
    dispatch(toggleMenu());
  },
  closeModal: () => {
    dispatch(closeModal());
  },
  changePeriod: (period: Period) => {
    dispatch(changePeriod(period));
  },
  toggleScreenSmall: () => {
    dispatch(toggleScreenSmall());
  },
  toggleScreenBig: () => {
    dispatch(toggleScreenBig());
  },
  loadShifts: (teamId, requestPeriod, isPoll) => {
    void dispatch(attemptLoadTeamRoster(teamId, requestPeriod, false, isPoll));
  },
  attemptGetGlobalSettings: () => {
    void dispatch(attemptGetGlobalSettings());
  },
  checkNewMessages: (personIds, from, to, isPoll) => {
    dispatch(attemptGetMessages(personIds, from, to, isPoll));
  },
  getHolidayArray: () => {
    void dispatch(getHolidays());
  },
  checkUnreadMessages: (teamIds, from, to, isPoll) => {
    dispatch(atteptGetUnreadMessagesCount(teamIds, from, to, isPoll));
  },
  hideErrorModal: () => dispatch(hideErrorModal()),
  queueToast: (text) => dispatch(queueToast({ text })),
  setLocationForTracking: (location) =>
    dispatch(setLocationForTracking(location)),
  setCurrentTeam(teamId) {
    dispatch(changeTeam(teamId));
  },
  setShowingRuleViolationInfo: (show) => {
    dispatch(setShowingRuleViolationInfo(show));
  },
  getShiftDefinitions: (teamId, period) => {
    dispatch(attemptGetShiftDefs(teamId, period));
  },
  logout: () => {
    void dispatch(logout());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

