import './ListViewItem.scss';

import * as React from 'react';
import * as dateFns from 'date-fns';

import Icons, { IconSize } from '@pdcfrontendui/components/Icons';
import { getListItemLabel, getShiftStatusStyling } from '../util/shifts';

import { Item } from '../components';
import { Period } from '../util/dates';
import { Tab } from '../routes';
import { TeamShiftStatusEnum } from '../api/enumLib_api';
import classNames from 'classnames';
import { currentLanguage } from '../currentLanguage';
import { dateFormats } from '@pdcfrontendui/utils';
import { getListViewItemAriaLabel } from './getListViewItemAriaLabel';

export type ListViewItemProps = {
  id: string;
  employeeName: string;
  status: TeamShiftStatusEnum;
  period: Period;
  isSelected: boolean;
  hasMessage?: boolean;
  unreadMessage?: boolean;
  selectShift: () => void;
  shiftInformationLabel: string;
  date: Date;
  selectedTab?: Tab;
  numSwapSuggestions: number | null;
  deviatingPayerUiLabel: string | null;
};

const ListViewItem = ({
  employeeName,
  id,
  period,
  status,
  isSelected,
  selectShift,
  hasMessage = false,
  unreadMessage = false,
  date,
  selectedTab,
  shiftInformationLabel,
  numSwapSuggestions,
  deviatingPayerUiLabel,
}: ListViewItemProps) => {
  const statusStyling = getShiftStatusStyling(status);
  const fromOverlaps = dateFns.isBefore(period.from, date);
  const toOverlaps = dateFns.isAfter(period.to, dateFns.addDays(date, 1));
  const ariaLabel = getListViewItemAriaLabel(employeeName, period.from);

  return (
    <Item
      id={`list-view-item-${dateFns.format(
        period.from,
        'YYYYMMDD'
      )}-${dateFns.format(period.from, 'HHmm')}-${id}`}
      className={'listview-item ' + statusStyling}
      onClick={selectShift}
      selected={isSelected}
      ariaLabel={ariaLabel}
      role="option"
    >
      <div className="content">
        <div className="period">
          <div
            className={classNames('from', statusStyling, {
              overlaps: fromOverlaps,
            })}
          >
            {`${fromOverlaps ? '(' : ''}${dateFormats.$23DOT59(period.from)}${
              fromOverlaps ? ')' : ''
            }`}
          </div>
          <div
            className={classNames('to', {
              overlaps: toOverlaps,
            })}
          >{`${toOverlaps ? '(' : ''}${dateFormats.End_23DOT59(period.to)}${
            toOverlaps ? ')' : ''
          }`}</div>
        </div>
        <div className="shift-info">
          <div className={'employee-name ' + statusStyling}>
            {status === TeamShiftStatusEnum.actionRequired && (
              <Icons.ExclamationFill size={IconSize.XXSmall} />
            )}

            {(status === TeamShiftStatusEnum.offered ||
              status === TeamShiftStatusEnum.swapActionRequired) && (
              <Icons.Swap size={IconSize.XXSmall} />
            )}

            {(status === TeamShiftStatusEnum.offerActionRequired ||
              status === TeamShiftStatusEnum.deputyOffered) && (
              <Icons.ArrowRight size={IconSize.XXSmall} />
            )}

            {employeeName}
          </div>
          <div className="shift-status">
            {status === TeamShiftStatusEnum.offerActionRequired
              ? currentLanguage.NumApplicantsFromShiftExchange_1(
                  numSwapSuggestions ?? 0
                )
              : getListItemLabel(status, shiftInformationLabel, selectedTab)}
          </div>
        </div>
      </div>
      <div className="content">
        {deviatingPayerUiLabel && (
          <Icons.Info className="deviantPayer" size={IconSize.XXXSmall} />
        )}
        {hasMessage && (
          <Icons.Chat
            className={classNames('message', { new: unreadMessage })}
            size={IconSize.XXXSmall}
          />
        )}
        <Icons.ChevronRight size={IconSize.XXXSmall} />
      </div>
    </Item>
  );
};
export default ListViewItem;
