import { useRef, useEffect } from 'react';

export const useDelayedInterval = (
  callback: () => void,
  interval: number,
  delay: number
) => {
  const savedCallback = useRef<() => void>();
  const intervalId = useRef<NodeJS.Timeout>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }
    const timeoutId = setTimeout(() => {
      const id = setInterval(tick, interval);
      intervalId.current = id;
    }, delay);
    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalId.current);
    };
  }, [interval, delay]);
};
